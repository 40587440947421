<template>
  <div>
    <div>
      <b-card >
        <b-card-title class="text-danger h2">Add Package</b-card-title>
        <b-form @submit.prevent class="pt-2">
          <validation-observer ref="PackageValidation">
            <b-row>
              <b-col md="12" class="mb-1">
                <b-form-group label="Package Category">
                  <validation-Provider
                    name="Package Category"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      placeholder=" Select Package Category"
                      v-model="selected1"
                      :options="packagecategories"
                      label="title"
                    ></v-select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-Provider>
                </b-form-group>
              </b-col>

              <b-col md="12" class="mb-1">
                <b-form-group label="Package Name">
                  <validation-Provider
                    name="Package Name"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      placeholder="Package Name"
                      v-model="form.name"
                    ></b-form-input>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-Provider>
                </b-form-group>
              </b-col>

              <b-col md="12" class="mb-1">
                <b-form-group label="Hotel Category">
                  <validation-Provider
                    name="Hotel Category"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      placeholder="Hotel Category"
                      v-model="selected"
                      :options="categories"
                      label="title"
                    ></v-select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-Provider>
                </b-form-group>
              </b-col>

              <b-col md="12" class="mb-1">
                <b-form-group label="Package Image">
                  <validation-Provider
                    name="Package Image"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-file v-model="file"></b-form-file>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-Provider>
                </b-form-group>
              </b-col>

              <b-col md="12" class="mb-1">
                <b-form-group label="Package Duration">
                  <validation-Provider
                    name="Package Duration"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      placeholder="Package Duration"
                      v-model="form.duration"
                    ></b-form-input>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-Provider>
                </b-form-group>
              </b-col>

              <b-col md="12" class="mb-1">
                <b-form-group label="Package Season">
                  <validation-Provider
                    name="Package Season"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      placeholder="Package Season"
                      v-model="form.season"
                    ></b-form-input>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-Provider>
                </b-form-group>
              </b-col>

              <b-col md="12" class="mb-1">
                <b-form-group label="Package Price">
                  <validation-Provider
                    name="Package Price"
                    rules="required|double"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      placeholder="Package Price"
                      v-model="form.price"
                    ></b-form-input>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-Provider>
                </b-form-group>
              </b-col>

              <b-col md="12" class="mb-1 text-center">
                <b-button
                  variant="primary"
                  @click="addPackage()"
                  block
                  type="submit"
                >
                  Submit Package
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BFormRadio,
  BFormGroup,
  BInputGroup,
  BFormTextarea,
  BForm,
  BTable,
  BBadge,
  BButton,
  BCol,
  BRow,
  BFormFile,
  BCardText,
  BLink,
  BFormInput,
  BContainer,
} from "bootstrap-vue";
import vSelect from "vue-select";
// import categoryApi from "@/Api/Modules/category";
import { ValidationObserver } from "vee-validate";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import packageApi from "@/Api/Modules/packages";

export default {
  name: "AddPackage",
  components: {
    BCard,
    BFormRadio,
    BFormFile,
    BForm,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    BFormTextarea,
    BFormTextarea,
    BFormGroup,
    BInputGroup,
    vSelect,
    BButton,
    BCol,
    BBadge,
    BTable,
    BRow,
    BContainer,
    BCardText,
    BLink,
  },
  data() {
    return {
      form: {},
      file: [],
      selected1: {
        title: "Select a Package Type",
      },
      selected: {
        title: "select a Hotel Type",
      },
      packagecategories: [
        {
          title: "Main",
        },
        {
          title: "Seasonal/Theme",
        },
      ],
      categories: [
        {
          title: "1 Star",
        },
        {
          title: "2 Star",
        },
        {
          title: "3 Star",
        },
        {
          title: "4 Star",
        },
        {
          title: "5 Star",
        },
        {
          title: "6 Star",
        },
        {
          title: "7 Star",
        },
      ],
    };
  },

  methods: {
    async addPackage() {
      if (await this.$refs.PackageValidation.validate()) {
        await this.$vs.loading({
          scale: 0.8,
        });

        let form = new FormData();
        form.append("name", this.form.name);
        form.append("image", this.file);
        form.append("duration", this.form.duration);
        form.append("season", this.form.season);
        form.append("price", this.form.price);
        form.append("hotel_category", this.selected.title);
        form.append("package_type", this.selected1.title);
        console.log(form);
        await packageApi
          .AddPackage(form)
          .then(() => {
            this.$vs.loading.close();
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      }
    },
  },
};
</script>
<style scoped></style>
