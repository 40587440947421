<template>
  <div>
    <div class="pt-2"></div>
    <div class="text-right">
      <b-button variant="primary" v-b-toggle.sidebar-1>Add Package</b-button>
    </div>
    <div class="pt-3">
      <PackageTable />
    </div>

    <b-sidebar id="sidebar-1"   shadow right>
      <AddPackage />
    </b-sidebar>
  </div>
</template>

<script>
import PackageTable from "./Components/PackageTable.vue";
import AddPackage from "./Components/AddPackage.vue";

import { BButton, BSidebar } from "bootstrap-vue";

export default {
  name: "packages",
  components: {
    PackageTable,
    AddPackage,
    BButton,
    BSidebar,
  },
};
</script>
