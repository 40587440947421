<template>
  <div>
    <div>
      <b-table sticky-header="" responsive :items="items" :fields="fields">
        <template #cell(image)="data">
          <b-img width="100%" :src="appUrl + '/' + data.value"></b-img>
        </template>
        <template #cell(action)="data">
          <b-row>
            <b-col md="auto">
              <b-button variant="success" @click="openUpdateModal(data.item)">
                <feather-icon icon="EditIcon" class="mr-25"
              /></b-button>
            </b-col>
            <b-col md="auto">
              <b-button variant="danger" @click="deletePackage(data.item.id)">
                <feather-icon icon="DeleteIcon" class="mr-25"
              /></b-button>
            </b-col>
          </b-row>
        </template>
      </b-table>
    </div>

    <b-modal
      ref="updatemodal"
      v-model="show"
      hide-footer
      title="Update Package"
    >
      <UpdatePackage :updatingdata="selectedItem" />
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BCard,
  BTable,
  BBadge,
  BButton,
  BCol,
  BRow,
  BCardText,
  BAvatar,
  BLink,
  BImg,
  BContainer,
} from "bootstrap-vue";
import UpdatePackage from "@/views/Packages/Components/UpdatePackage.vue";
import packageApi from "@/Api/Modules/packages";
import { $themeConfig } from "@themeConfig";

export default {
  name: "Packages",
  components: {
    BCard,
    BModal,
    BImg,
    BButton,
    BCol,
    BBadge,
    BAvatar,
    UpdatePackage,
    BTable,
    BRow,
    BContainer,
    BCardText,
    BLink,
  },
  data() {
    return {
      show: false,
      selectedItem: {},
      fields: [
      {
          key: "package_type",
          label: "Package Type",
          sortable: true,
          // thStyle: { width: "2%" },
          // tdClass: "custom-cell-padding",
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
          // thStyle: { width: "2%" },
          // tdClass: "custom-cell-padding",
        },
        {
          key: "price",
          label: "Price",
          sortable: true,
          // thStyle: { width: "2%" },
          // tdClass: "custom-cell-padding",
        },

        {
          key: "image",
          label: "Image",
          sortable: true,
          // thStyle: { width: "2%" },
          // tdClass: "custom-cell-padding",
        },

        {
          key: "duration",
          label: "Duration",
          sortable: true,
          // thStyle: { width: "2%" },
          // tdClass: "custom-cell-padding",
        },
        {
          key: "season",
          label: "Season",
          sortable: true,
          // thStyle: { width: "2%" },
          // tdClass: "custom-cell-padding",
        },
        {
          key: "hotel_category",
          label: "Hotel Type",
          sortable: true,
          // thStyle: { width: "2%" },
          // tdClass: "custom-cell-padding",
        },
        {
          key: "action",
          label: "Action",
          sortable: true,
          // thStyle: { width: "2%" },
          // tdClass: "custom-cell-padding",
        },
      ],
      items: [],
    };
  },
  setup() {
    // App Name
    const { appUrl } = $themeConfig.app;
    return {
      appUrl,
    };
  },
  async created() {
    await this.AllPackages();
  },

  methods: {
    setCellPadding(value, key, item) {
      // Add a custom class to table cells based on your requirements
      return "custom-cell-padding";
    },
    async AllPackages() {
      await this.$vs.loading({
        scale: 0.8,
      });
      const res = await packageApi.AllPackages().catch(() => {
        this.$vs.loading.close();
      });
      this.items = res.data.data;
      this.$vs.loading.close();
    },

    async deletePackage(id) {
      await this.$vs.loading({
        scale: 0.8,
      });
      await packageApi
        .DeletePackage(id)
        .then(() => {
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    openUpdateModal(item) {
      this.selectedItem = item;
      this.show = true;
    },
  },
};
</script>

<style>
.custom-cell-padding {
  height: 70px; /* Set your desired padding value */
}
</style>
