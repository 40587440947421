<template>
  <div>
    <div>
      <b-card>
        <b-form @submit.prevent>
          <validation-observer ref="PackageValidation">
            <b-row>
              <b-col md="12" class="mb-1">
                <b-form-group label="Package Name">
                  <validation-Provider
                    name="Package Name"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      placeholder="Package Name"
                      v-model="form.name"
                    ></b-form-input>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-Provider>
                </b-form-group>
              </b-col>

              <b-col md="12" class="mb-1">
                <b-form-group label="Hotel Category">
                  <validation-Provider
                    name="Package Name"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      placeholder="Hotel Category"
                      v-model="selected"
                      :options="categories"
                      label="title"
                    ></v-select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-Provider>
                </b-form-group>
              </b-col>

              <b-col md="12" class="mb-1">
                <b-form-group label="Package Image">
                  <validation-Provider name="Package Image" v-slot="{ errors }">
                    <img v-if="url" width="30%" :src="url" class="img-fluid" />
                    <img
                      v-else
                      width="30%"
                      :src="appUrl + '/' + updatingdata.image"
                      class="img-fluid"
                    />
                    <b-form-file
                      v-model="file"
                      @change="previewImage"
                    ></b-form-file>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-Provider>
                </b-form-group>
              </b-col>

              <b-col md="12" class="mb-1">
                <b-form-group label="Package Duration">
                  <validation-Provider
                    name="Package Duration"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      placeholder="Package Duration"
                      v-model="form.duration"
                    ></b-form-input>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-Provider>
                </b-form-group>
              </b-col>

              <b-col md="12" class="mb-1">
                <b-form-group label="Package Season">
                  <validation-Provider
                    name="Package Season"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      placeholder="Package Season"
                      v-model="form.season"
                    ></b-form-input>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-Provider>
                </b-form-group>
              </b-col>

              <b-col md="12" class="mb-1">
                <b-form-group label="Package Price">
                  <validation-Provider
                    name="Package Price"
                    rules="required|double"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      placeholder="Package Price"
                      v-model="form.price"
                    ></b-form-input>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-Provider>
                </b-form-group>
              </b-col>

              <b-col md="12" class="mb-1 text-center">
                <b-button variant="primary" @click="updatePackage()" block>
                  Update Package
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BFormRadio,
  BFormGroup,
  BInputGroup,
  BFormTextarea,
  BForm,
  BTable,
  BBadge,
  BButton,
  BCol,
  BRow,
  BFormFile,
  BCardText,
  BLink,
  BFormInput,
  BContainer,
} from "bootstrap-vue";
import vSelect from "vue-select";
// import categoryApi from "@/Api/Modules/category";
import { ValidationObserver } from "vee-validate";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import packageApi from "@/Api/Modules/packages";
import { $themeConfig } from "@themeConfig";

export default {
  name: "AddPackage",
  components: {
    BCard,
    BFormRadio,
    BFormFile,
    BForm,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    BFormTextarea,
    BFormTextarea,
    BFormGroup,
    BInputGroup,
    vSelect,
    BButton,
    BCol,
    BBadge,
    BTable,
    BRow,
    BContainer,
    BCardText,
    BLink,
  },
  data() {
    return {
      form: {},
      file:'',
      selected: {
        title: "",
      },
      categories: [
        {
          title: "1 Star",
        },
        {
          title: "2 Star",
        },
        {
          title: "3 Star",
        },
        {
          title: "4 Star",
        },
        {
          title: "5 Star",
        },
        {
          title: "6 Star",
        },
        {
          title: "7 Star",
        },
      ],
      url: "",
    };
  },
  props: {
    updatingdata: Object,
  },
  created() {
    this.initializeData();
  },
  setup() {
    // App Name
    const { appUrl } = $themeConfig.app;
    return {
      appUrl,
    };
  },
  methods: {
    previewImage: function (event) {
      var input = event.target;

      var reader = new FileReader();
      reader.readAsDataURL(input.files[0]);
      reader.onload = (e) => {
        this.url = e.target.result;
      };
    },
    initializeData() {
      this.form = this.updatingdata;

      this.selected.title = this.updatingdata.hotel_category;
    },
    async updatePackage() {
      if (await this.$refs.PackageValidation.validate()) {
        await this.$vs.loading({
          scale: 0.8,
        });
        let form = new FormData();

        if (this.file != "") {
          form.append("image", this.file);
        } else {
          form.append("image_url", this.form.image);
        }
        form.append("name", this.form.name);
        form.append("id", this.form.id);
        form.append("duration", this.form.duration);
        form.append("season", this.form.season);
        form.append("price", this.form.price);
        form.append("hotel_category", this.selected.title);
        await packageApi
          .UpdatePackage(form)
          .then(() => {
            this.$vs.loading.close();
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      }
    },
  },
};
</script>
<style scoped></style>
