var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('b-card',[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-observer',{ref:"PackageValidation"},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Package Name"}},[_c('validation-Provider',{attrs:{"name":"Package Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Package Name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Hotel Category"}},[_c('validation-Provider',{attrs:{"name":"Package Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":"Hotel Category","options":_vm.categories,"label":"title"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Package Image"}},[_c('validation-Provider',{attrs:{"name":"Package Image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.url)?_c('img',{staticClass:"img-fluid",attrs:{"width":"30%","src":_vm.url}}):_c('img',{staticClass:"img-fluid",attrs:{"width":"30%","src":_vm.appUrl + '/' + _vm.updatingdata.image}}),_c('b-form-file',{on:{"change":_vm.previewImage},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Package Duration"}},[_c('validation-Provider',{attrs:{"name":"Package Duration","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Package Duration"},model:{value:(_vm.form.duration),callback:function ($$v) {_vm.$set(_vm.form, "duration", $$v)},expression:"form.duration"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Package Season"}},[_c('validation-Provider',{attrs:{"name":"Package Season","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Package Season"},model:{value:(_vm.form.season),callback:function ($$v) {_vm.$set(_vm.form, "season", $$v)},expression:"form.season"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Package Price"}},[_c('validation-Provider',{attrs:{"name":"Package Price","rules":"required|double"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Package Price"},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1 text-center",attrs:{"md":"12"}},[_c('b-button',{attrs:{"variant":"primary","block":""},on:{"click":function($event){return _vm.updatePackage()}}},[_vm._v(" Update Package ")])],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }